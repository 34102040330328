import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTables = Loadable(lazy(() => import('./tables/AdminUser')));
const AppGrid = Loadable(lazy(() => import('./grid/GridExample')));
const AppTestGrid = Loadable(lazy(() => import('./grid/GridTestExample')));

const Versions = Loadable(lazy(() => import('./versions/Versions')));

const MarkettingVersions = Loadable(lazy(() => import('./versions/MarkettingVersions')));

const AdminPackages = Loadable(lazy(() => import('../material-kit/adminpackages/AdminPackages')));
const Products = Loadable(lazy(() => import('./products/Products')));
const ProdecedRobots = Loadable(lazy(() => import('./products/ProducedRobots')));
const AboutRobots = Loadable(lazy(() => import('./products/about/AboutRobots')));
const HardwareDiagnostic = Loadable(lazy(() => import('./products/Hardware/HardwareDiagnostic')));

const VersionCard = Loadable(lazy(() => import('./versions/VersionCards')));
const Profile = Loadable(lazy(() => import('app/views/dashboard/LayotProfile/Profile')));
const AboutVersion = Loadable(lazy(() => import('./versions/AboutVersion')));
const MarkettingAboutVersion = Loadable(lazy(() => import('./versions/MarkettingAboutVersion')));

const Software = Loadable(lazy(() => import('./software/Software')));
const SoftwareCard = Loadable(lazy(() => import('./software/SoftwareCard')));
const Marketing = Loadable(lazy(() => import('./Marketing/Marketing')));
const MarketingCard = Loadable(lazy(() => import('./Marketing/MarketingCard')));
const Programs = Loadable(lazy(() => import('./tester/ProgramsNew')));
const VersionTest = Loadable(() => import('./tester/VersionTest'));
const CreateAros = Loadable(lazy(() => import('./tester/aros/CreateAros')));
const CreateTestAros = Loadable(lazy(() => import('./tester/aros/CreateTestAros')));
const ArosVersion = Loadable(lazy(() => import('./tester/versions/VersionHome')));
const RobotVersion = Loadable(lazy(() => import('./products/RobotAros')));
const RobotSidebar = Loadable(lazy(() => import('./tester/SidebarNew')));
const UploadSoftware = Loadable(lazy(() => import('./versions/UploadSoftware')));
const UploadMarkettingSoftware = Loadable(lazy(() => import('./versions/UploadMarkettingSoftware')));

const EditSoftware = Loadable(lazy(() => import('./versions/EditFile')));
const MarkettingEditSoftware = Loadable(lazy(() => import('./versions/MarkettingEditFile')));

const CustomerSidebar = Loadable(lazy(() => import('./customer/CustomerSidebar')));
const CustomerVersion = Loadable(lazy(() => import('./customer/CustomerVersion')));
const AdminAros = Loadable(lazy(() => import('./products/AdminAros')));
const RobotModel = Loadable(lazy(() => import('./robotmodel/RobotModel')));
const RemoteConnection = Loadable(lazy(() => import('./RemoteConnection/RemoteConnection')));
const ConnectedUser = Loadable(lazy(() => import('./RemoteConnection/ConnectedUser')));

const SortableList = Loadable(lazy(() => import('../SortableList')));
const TestSortableList = Loadable(lazy(() => import('../TestSortableList')));

const SoftwareLibraries = Loadable(lazy(() => import('../../views/material-kit/products/about/SoftwareLibraries')));
const RobotUsers = Loadable(lazy(() => import('../../components/RobotUser')));

const materialRoutes = [
  { path: '/users', element: <AppTables /> },
  { path: '/product/arosversions', element: <AppGrid /> },
  { path: '/product/testarosversions', element: <AppTestGrid /> },
  { path: '/versions', element: <Versions /> },
  { path: '/markettingversions', element: <MarkettingVersions /> },
  { path: '/product/packages', element: <AdminPackages /> },
  { path: '/product', element: <Products /> },
  { path: '/product/producedrobots', element: <ProdecedRobots /> },
  { path: 'product/producedrobots/aboutrobots', element: <AboutRobots /> },
  { path: '/material/versioncard', element: <VersionCard /> },
  { path: '/profile', element: <Profile /> },
  { path: '/material/aboutversion', element: <AboutVersion /> },
  { path: '/material/softwarelibraries/:id', element: <SoftwareLibraries /> },
  { path: '/material/markettingaboutversion', element: <MarkettingAboutVersion /> },
  { path: '/softwarecard/software', element: <Software /> },
  { path: '/softwarecard', element: <SoftwareCard /> },
  { path: '/markettingcard/marketting', element: <Marketing /> },
  { path: '/programs', element: <Programs /> },
  { path: '/material/versiontest', element: <VersionTest /> },
  { path: '/createaros', element: <CreateAros /> },
  { path: '/createtestaros', element: <CreateTestAros /> },
  { path: '/arosversions', element: <ArosVersion /> },
  { path: '/robotversion', element: <RobotVersion /> },
  { path: '/robotsidebar', element: <RobotSidebar /> },
  { path: '/uploadsoftware', element: <UploadSoftware /> },
  { path: '/uploadmarkettingsoftware/:packageId', element: <UploadMarkettingSoftware /> },
  { path: '/editsoftware', element: <EditSoftware /> },
  { path: '/markettingeditsoftware', element: <MarkettingEditSoftware /> },
  { path: '/customersidebar', element: <CustomerSidebar /> },
  { path: '/customerversion', element: <CustomerVersion /> },
  { path: '/adminaros', element: <AdminAros /> },
  { path: '/productmodel', element: <RobotModel /> },
  { path: '/remoteconnection', element: <RemoteConnection /> },
  { path: '/SortArosPackage', element: <SortableList /> },
  { path: '/TestSortArosPackage', element: <TestSortableList /> },
  { path: '/HardwareDiagnostic', element: <HardwareDiagnostic /> },
  { path: '/connecteduser', element: <ConnectedUser /> },
  { path: "/robotusers/:RobotId", element: <RobotUsers /> }
];

export default materialRoutes;
