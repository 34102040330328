import { USERS_NOT_IN_ROBOT } from "../actions/actions";

const INITIAL_STATE = {
    TotalCount: 0,
    Users: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USERS_NOT_IN_ROBOT: {
            return {
                ...state,
                TotalCount: action.TotalCount,
                Users: action.Users,
            };
        }
        default:
            return state;
    }
};
